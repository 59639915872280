/* Spinner.css */
.spinner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Full viewport height */
}

.spinner {
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid #000000; /* Black */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
}

/* Button spinner variant */
.spinner-container-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.spinner-container-button .spinner {
  border-width: 3px;
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
